body {
  -ms-overflow-style: none;
  /* scrollbar-width: none; */
  box-sizing: border-box;

  max-width: 100vw;
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

/* body::-webkit-scrollbar {
  display: none;
} */

a {
  cursor: pointer;
  text-decoration: none;
}

a:hover {
  cursor: pointer;
  text-decoration: underline;
}

/* SCROLL BAR STYLES */

::-webkit-scrollbar {
  width: 8px;
  height: 6px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #aba7a7;
  border-left: 3px solid white;
  border-right: 3px solid white;
  border-top: 2px solid white;
  border-bottom: 2px solid white;
}

::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 10px;
}

.cursor-pointer {
  cursor: pointer;
}
